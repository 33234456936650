import axios from "axios";
import toast from "react-hot-toast";

const apiClient = axios.create({
  baseURL: "https://mh7-be-production.up.railway.app/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const sendMessage = async (data, setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await apiClient.post("api/contact-us", {
      ...data,
      site: "cleaning",
    });
    toast.success(response?.data?.success || "message sent successfully");
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    toast.error("Error sending message");
  }
};

export const applyNow = async (data, setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await apiClient.post("api/apply-application", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success("Application submitted! We will get back to you soon.");
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    toast.error("Error submitting application");
  }
};
export const contractApi = async (data, setIsLoading) => {
  try {
    setIsLoading(true);
    const response = await apiClient.post("api/contract-application", data);
    toast.success("Application submitted! We will get back to you soon.");
    setIsLoading(false);
    return response.data;
  } catch (error) {
    setIsLoading(false);
    toast.error("Error submitting application");
  }
};
